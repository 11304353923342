import React from 'react'
import Layout from 'src/theme/Layout'
import { Router } from '@reach/router'
import PATHS from 'src/utils/paths'
import ResetPasswordInit from 'src/features/auth/ResetPasswordInit'
import ResetPasswordFinish from 'src/features/auth/ResetPasswordFinish'
import styled from 'styled-components'
import Paper from 'src/components/atoms/Paper'
import AuthFormContainer from 'src/components/AuthFormContainer'
import Metadata from 'src/components/Metadata'
import { META } from 'src/utils/seo'
import BubbleBackground from 'src/components/BubbleBackground'
import VerticalAlignContainer from 'src/components/VerticalAlignContainer'

export const ResetPassword = props => {
  return (
    <Layout>
      <BubbleBackground>
        <Metadata meta={META.landingPage} />
        <VerticalAlignContainer>
          <AuthFormContainer>
            <Paper>
              <ResetPasswordFormContainer>
                <Router>
                  <ResetPasswordInit path={PATHS.resetPasswordInit} />
                  <ResetPasswordFinish path={PATHS.resetPasswordFinish} />
                </Router>
              </ResetPasswordFormContainer>
            </Paper>
          </AuthFormContainer>
        </VerticalAlignContainer>
      </BubbleBackground>
    </Layout>
  )
}

export default ResetPassword

const ResetPasswordFormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`
