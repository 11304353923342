import React, { useState, useEffect, useCallback } from 'react'
import { pathOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { META } from 'src/utils/seo'
import { SEVERITY } from 'src/utils/toast'
import PATHS from 'src/utils/paths'
import {
  validateResetPasswordFinishField,
  validateResetPasswordFinishValues
} from 'src/features/auth/duck/schema'

import {
  resetPasswordFinish,
  validateResetPasswordToken
} from 'src/services/AuthService'

import { showToastRoutine } from 'src/features/toast/duck/actions'

import Button from 'src/components/atoms/Button'
import PaperTitle from 'src/components/atoms/PaperTitle'
import Metadata from 'src/components/Metadata'
import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'

const emptyValues = {
  password: '',
  repeatPassword: ''
}

export const ResetPasswordInit = props => {
  const { id, token, navigate } = props
  //  REDUX
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  // STATE
  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  const validateToken = async e => {
    try {
      const response = await validateResetPasswordToken({ token, id })
      const isValid = pathOr(false, ['data', 'isValid'])(response)
      !isValid && navigate(PATHS.logIn)
      !isValid &&
        showToast({
          key: 'toast.resetPasswordLinkInvalid',
          severity: SEVERITY.error
        })
    } catch (e) {
      console.error(e)
      navigate(PATHS.logIn)
      showToast({
        key: 'toast.resetPasswordLinkInvalid',
        severity: SEVERITY.error
      })
    }
  }

  useEffect(() => {
    validateToken()
  }, [])

  useEffect(() => {
    validateResetPasswordFinishValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    const { password } = values
    e.preventDefault()
    try {
      await resetPasswordFinish({ password, token, id })
      navigate(PATHS.logIn)
      showToast({
        key: 'toast.finishResetPassword',
        severity: SEVERITY.success
      })
    } catch (e) {
      showToast({ key: 'toast.somethingWentWrong', severity: SEVERITY.error })
    }
  }

  return (
    <>
      <Metadata meta={META.resetPassword} />
      <PaperTitle>{t('resetPasswordPage.finish.title')}</PaperTitle>
      <p>{t('resetPasswordPage.finish.text')}</p>
      <Form onSubmit={handleSubmit}>
        <Input
          required
          name='password'
          type='password'
          label={t('form.password.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordFinishField(values)}
        />
        <Input
          required
          name='repeatPassword'
          type='password'
          label={t('form.repeatPassword.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordFinishField(values)}
        />
        <ButtonContainer>
          <Button type='submit' disabled={!valid} onClick={handleSubmit}>
            {t('resetPasswordPage.finish.resetPasswordCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default ResetPasswordInit

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
