import React, { useState, useEffect, useCallback } from 'react'
import { pipe, propOr } from 'ramda'
import { useLocation } from '@reach/router'
import qs from 'qs'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SEVERITY } from 'src/utils/toast'
import { META } from 'src/utils/seo'
import {
  validateResetPasswordInitField,
  validateResetPasswordInitValues
} from 'src/features/auth/duck/schema'
import { resetPasswordInit } from 'src/services/AuthService'

import { showToastRoutine } from 'src/features/toast/duck/actions'

import Form from 'src/components/atoms/Form'
import Input from 'src/components/atoms/Input'
import Metadata from 'src/components/Metadata'
import Button from 'src/components/atoms/Button'
import PaperTitle from 'src/components/atoms/PaperTitle'
import { parseStringToBoolean } from 'src/utils/string'

const emptyValues = {
  email: '',
  e2e: false
}

export const ResetPasswordInit = props => {
  const { search } = useLocation()

  //  REDUX
  const dispatch = useDispatch()
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const query = qs.parse(search, { ignoreQueryPrefix: true })

  const [valid, _validateSchema] = useState(false)
  const [values, setValues] = useState(emptyValues)

  useEffect(() => {
    setValues(prevValues =>
      ({
        ...prevValues,
        e2e: pipe(
          propOr(false, 'e2e'),
          parseStringToBoolean
        )(query)
      }))
  }, [])

  useEffect(() => {
    validateResetPasswordInitValues(values, _validateSchema)
  }, [values])

  const { t } = useTranslation()
  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    await resetPasswordInit(values)
    showToast({ key: 'toast.initResetPassword', severity: SEVERITY.success })
  }

  console.log(values)

  return (
    <>
      <Metadata meta={META.resetPassword} />
      <PaperTitle>{t('resetPasswordPage.init.title')}</PaperTitle>
      <p>{t('resetPasswordPage.init.text')}</p>
      <Form onSubmit={handleSubmit}>
        <Input
          required
          name='email'
          label={t('form.email.label')}
          onChange={handleOnChange}
          validate={validateResetPasswordInitField(values)}
        />
        <ButtonContainer>
          <Button type='submit' disabled={!valid} onClick={handleSubmit}>
            {t('resetPasswordPage.init.resetPasswordCTA')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  )
}

export default ResetPasswordInit

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`
